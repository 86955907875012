import React, { useEffect, useMemo, useState } from "react";
import {
  ReactFormGenerator,
  FormGeneratorOnSubmitParams,
} from "react-form-builder2";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createCustomSurveyResponseRequest,
  createGlobalCustomSurveyResponseRequest,
  getCustomerSurveyRequest,
  setSurveyPage,
  updateCustomSurveyResponseRequest,
  updateGlobalCustomSurveyResponseRequest,
} from "../../store/slices/surveys/actions";
import {
  selectCreateCustomSurveyResponsePending,
  selectCustomerSurvey,
  selectCustomerSurveyResponseId,
  selectGetCustomerSurveyPending,
  selectSurveyPage,
  selectSurveyRedirectUrl,
} from "../../store/slices/surveys/selectors";
import Page404 from "../organisms/Page404";
import Spinner from "../atoms/Spinner";
import { useTranslation } from "react-i18next";
import RawHtml from "../atoms/RawHtml";
import { getLocationFromCustomerIdRequest } from "../../store/slices/locations/actions";
import {
  selectCustomerLocation,
  selectGetCustomerLocationPending,
} from "../../store/slices/locations/selectors";
import ToggleSwitch from "../atoms/ToggleSwitch";
import { IPublicLocation } from "../../store/slices/locations/types";

type StringMapping = { [key: string]: string };

const PageContent = ({
  children,
  primaryColor = "#292E42",
  secondaryColor = "#4A5568",
  textColor = "#FFFFFF",
}: {
  children: React.ReactNode;
  primaryColor?: string;
  secondaryColor?: string;
  textColor?: string;
}) => {
  return (
    <div className="tw-overflow-auto tw-h-screen">
      <div
        className="tw-flex tw-justify-center tw-align-center survey-form tw-min-h-screen"
        style={{ backgroundColor: primaryColor }}
      >
        <div
          className="tw-my-8 tw-mx-4 tw-w-full sm:tw-p-9 tw-p-4 tw-rounded-lg tw-shadow-pop"
          style={{
            maxWidth: "700px",
            backgroundColor: secondaryColor,
            color: textColor,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

const SubmitButton = ({
  customerLocation,
}: {
  customerLocation: IPublicLocation;
}) => {
  const { t } = useTranslation();
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-w-full">
      <button
        type="submit"
        className="tw-text-sm tw-p-4 tw-mb-6 tw-bg-primary tw-rounded-xl tw-text-white tw-font-semibold tw-mt-5"
        style={{
          backgroundColor: customerLocation?.button_color,
          color: customerLocation?.button_text_color,
        }}
      >
        {t("shared.next")}
      </button>
    </div>
  );
};

const Survey = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [extraFreeText, setExtraFreeText] = useState("");
  const [contactMe, setContactMe] = useState(false);

  const step = useSelector(selectSurveyPage);
  const surveySubmitPending = useSelector(
    selectCreateCustomSurveyResponsePending
  );

  const customerSurveyResponseId = useSelector(
    selectCustomerSurveyResponseId()
  );

  useEffect(() => {
    if (customerId) {
      dispatch(getCustomerSurveyRequest(customerId));
      dispatch(getLocationFromCustomerIdRequest(customerId));
    }
  }, [customerId, dispatch]);

  const customerSurvey = useSelector(selectCustomerSurvey);
  const getCustomerSurveyPending = useSelector(selectGetCustomerSurveyPending);
  const surveyRedirectUrl = useSelector(selectSurveyRedirectUrl);

  const customerLocation = useSelector(selectCustomerLocation);
  const getCustomerLocationPending = useSelector(
    selectGetCustomerLocationPending
  );

  useEffect(() => {
    const styleElem = document.head.appendChild(
      document.createElement("style")
    );
    styleElem.innerHTML = `
      .custom-control-input:checked~.custom-control-label::before {
        background-color: ${customerLocation?.button_color};
      }

      .slider-selection {
        background-color: ${customerLocation?.button_color};
      }

      .slider-handle {
        border-color: ${customerLocation?.button_color};
      }

      .survey-form .label-required.badge-danger,
      .survey-form .label-required.badge-danger::after {
        background-color: ${customerLocation?.secondary_color};
      }
    `;

    return () => {
      document.head.removeChild(styleElem);
    };
  }, [customerLocation]);

  const fieldNamesToIds = useMemo<StringMapping>(() => {
    const mappings: StringMapping = {};
    if (customerSurvey) {
      const formJson = customerSurvey.form_json;
      for (const field of formJson) {
        mappings[field.field_name] = field.id;
      }

      return mappings;
    }
    return {};
  }, [customerSurvey]);

  if (!customerId) {
    return null;
  }

  if (getCustomerSurveyPending || getCustomerLocationPending) {
    return (
      <div className="tw-w-screen tw-h-screen tw-flex tw-items-center tw-justify-center">
        <Spinner theme="dark" />
      </div>
    );
  }

  if (!customerSurvey || !customerLocation) {
    return (
      <div className="tw-w-screen tw-h-screen tw-flex tw-items-center tw-justify-center">
        <Page404 />
      </div>
    );
  }

  return (
    <PageContent
      primaryColor={customerLocation?.primary_color || undefined}
      secondaryColor={customerLocation?.secondary_color || undefined}
      textColor={customerLocation?.text_color || undefined}
    >
      {step === "START" && (
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full">
          <div>
            <RawHtml html={customerSurvey.welcome_message} />
          </div>
          <button
            className="tw-text-sm tw-p-4 tw-mb-6 tw-bg-primary tw-rounded-xl tw-text-white tw-font-semibold tw-mt-5"
            onClick={() => dispatch(setSurveyPage("SURVEY"))}
            style={{
              backgroundColor: customerLocation?.button_color,
              color: customerLocation?.button_text_color,
            }}
          >
            {t("shared.next")}
          </button>
        </div>
      )}
      {step === "SURVEY" && (
        <ReactFormGenerator
          action_name={t("shared.next")}
          form_action=""
          form_method="POST"
          onSubmit={(formData) => {
            if (surveySubmitPending) return;

            const submitData: {
              [key: string]: FormGeneratorOnSubmitParams;
            } = {};
            for (const fieldResponse of formData) {
              const fieldId = fieldNamesToIds[fieldResponse.name];
              submitData[fieldId] = fieldResponse;
            }

            const body = {
              raw: submitData,
              customer: customerId,
              survey: customerSurvey.id,
              useConditionalQuestions:
                customerSurvey.contact_me_toggle ||
                customerSurvey.extra_text_field,
            };
            if (customerSurvey.is_global) {
              dispatch(createGlobalCustomSurveyResponseRequest(body));
            } else {
              dispatch(createCustomSurveyResponseRequest(body));
            }
          }}
          // Type errors in the library so this gives an error
          // @ts-ignore
          submitButton={<SubmitButton customerLocation={customerLocation} />}
          data={customerSurvey.form_json as any[]}
        />
      )}
      {step === "FINISHED_POSITIVE" && (
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full">
          <RawHtml html={customerSurvey.good_response_message} />
          {surveyRedirectUrl ? (
            <button
              className="tw-text-sm tw-p-4 tw-mb-6 tw-bg-primary tw-rounded-xl tw-text-white tw-font-semibold tw-mt-5"
              onClick={() => {
                window.location.href = surveyRedirectUrl;
              }}
              style={{
                backgroundColor: customerLocation?.button_color,
                color: customerLocation?.button_text_color,
              }}
            >
              {t("shared.finish")}
            </button>
          ) : (
            <div>{t("views.surveys.youMayClose")}</div>
          )}
        </div>
      )}
      {step === "CONDITIONAL_QUESTIONS" && (
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full">
          <h2 className="tw-text-xl mb-4">
            {t("views.surveys.conditionalQuestionsHeader")}
          </h2>
          {customerSurvey.extra_text_field && (
            <div className="tw-flex tw-flex-col tw-items-center">
              {t("views.surveys.conditionalFreeTextLabel")}
              <textarea
                value={extraFreeText}
                onChange={(e) => setExtraFreeText(e.target.value)}
                className="tw-text-black tw-border tw-border-divider tw-rounded-lg tw-p-4 tw-my-4 tw-shadow-md tw-w-full tw-bg-white tw-h-32"
              />
            </div>
          )}
          {customerSurvey.contact_me_toggle && (
            <div className="tw-flex tw-flex-col tw-items-center mt-6">
              {t("views.surveys.conditionalContactMeLabel")}
              <div className="tw-flex tw-flex-row tw-items-center mt-2">
                <ToggleSwitch checked={contactMe} onChange={setContactMe} />
                <div className="ml-4">{t("views.surveys.contactMe")}</div>
              </div>
            </div>
          )}
          {
            <button
              className="tw-text-sm tw-p-4 tw-mb-6 tw-bg-primary tw-rounded-xl tw-text-white tw-font-semibold tw-mt-5"
              onClick={() => {
                if (customerSurvey.is_global) {
                  dispatch(
                    updateGlobalCustomSurveyResponseRequest({
                      responseId: customerSurveyResponseId || -1,
                      customer: customerId,
                      extra_free_text_feedback: extraFreeText,
                      requested_contact: contactMe,
                    })
                  );
                } else {
                  dispatch(
                    updateCustomSurveyResponseRequest({
                      responseId: customerSurveyResponseId || -1,
                      customer: customerId,
                      extra_free_text_feedback: extraFreeText,
                      requested_contact: contactMe,
                    })
                  );
                }
              }}
              style={{
                backgroundColor: customerLocation?.button_color,
                color: customerLocation?.button_text_color,
              }}
            >
              {t("shared.next")}
            </button>
          }
        </div>
      )}
      {step === "FINISHED_NEGATIVE" && (
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full">
          <RawHtml html={customerSurvey.bad_response_message} />
        </div>
      )}
    </PageContent>
  );
};

export default Survey;
