import { createStandardAction } from "typesafe-actions";
import { APIRequestConfig } from "../../types";
import {
  ICustomSurvey,
  IGlobalCustomSurvey,
  ICustomSurveyResponse,
  SurveyMetrics,
  SurveyPageName,
} from "./types";
import { requestActionCreator } from "../../actionCreators";
import { FormGeneratorOnSubmitParams } from "react-form-builder2";

export const getSurveysRequest = () => {
  return requestActionCreator("@surveys/getSurveys")({
    request: {
      url: "/surveys/custom-survey",
      method: "GET",
    },
  });
};
export const getSurveysPending = createStandardAction(
  "@surveys/getSurveysPending"
)();
export const getSurveysSuccess = createStandardAction(
  "@surveys/getSurveysSuccess"
)<ICustomSurvey[], { originalRequest: APIRequestConfig }>();
export const getSurveysError = createStandardAction(
  "@surveys/getSurveysError"
)();

export const getGlobalSurveysRequest = () => {
  return requestActionCreator("@surveys/getGlobalSurveys")({
    request: {
      url: "/surveys/global-custom-survey",
      method: "GET",
    },
  });
};
export const getGlobalSurveysPending = createStandardAction(
  "@surveys/getGlobalSurveysPending"
)();
export const getGlobalSurveysSuccess = createStandardAction(
  "@surveys/getGlobalSurveysSuccess"
)<IGlobalCustomSurvey[], { originalRequest: APIRequestConfig }>();
export const getGlobalSurveysError = createStandardAction(
  "@surveys/getGlobalSurveysError"
)();

export const createSurveyRequest = (payload: {
  company: number;
  form_json: any[];
  title: string;
  good_conditions_json: { [key: string]: any };
  contact_me_toggle: boolean;
  extra_text_field: boolean;
  locations?: number[];
}) => {
  return requestActionCreator("@surveys/createSurvey")({
    request: {
      url: "/surveys/custom-survey",
      method: "POST",
      payload,
    },
  });
};
export const createSurveyPending = createStandardAction(
  "@surveys/createSurveyPending"
)();
export const createSurveySuccess = createStandardAction(
  "@surveys/createSurveySuccess"
)<ICustomSurvey, { originalRequest: APIRequestConfig }>();
export const createSurveyError = createStandardAction(
  "@surveys/createSurveyError"
)();

export const archiveSurveyRequest = (id: number) => {
  return requestActionCreator("@surveys/archiveSurvey")({
    request: {
      url: `/surveys/custom-survey/${id}`,
      method: "PATCH",
      payload: { state: "ARCHIVED" },
    },
  });
};
export const archiveSurveyPending = createStandardAction(
  "@surveys/archiveSurveyPending"
)();
export const archiveSurveySuccess = createStandardAction(
  "@surveys/archiveSurveySuccess"
)<ICustomSurvey, { originalRequest: APIRequestConfig }>();
export const archiveSurveyError = createStandardAction(
  "@surveys/archiveSurveyError"
)();

export const unarchiveSurveyRequest = (id: number) => {
  return requestActionCreator("@surveys/unarchiveSurvey")({
    request: {
      url: `/surveys/custom-survey/${id}`,
      method: "PATCH",
      payload: { state: "ACTIVE" },
    },
  });
};
export const unarchiveSurveyPending = createStandardAction(
  "@surveys/unarchiveSurveyPending"
)();
export const unarchiveSurveySuccess = createStandardAction(
  "@surveys/unarchiveSurveySuccess"
)<ICustomSurvey, { originalRequest: APIRequestConfig }>();
export const unarchiveSurveyError = createStandardAction(
  "@surveys/unarchiveSurveyError"
)();

export const patchSurveyRequest = (
  id: number,
  payload: Partial<ICustomSurvey>
) => {
  return requestActionCreator("@surveys/patchSurvey")({
    request: {
      url: `/surveys/custom-survey/${id}`,
      method: "PATCH",
      payload,
    },
  });
};
export const patchSurveyPending = createStandardAction(
  "@surveys/patchSurveyPending"
)();
export const patchSurveySuccess = createStandardAction(
  "@surveys/patchSurveySuccess"
)<ICustomSurvey, { originalRequest: APIRequestConfig }>();
export const patchSurveyError = createStandardAction(
  "@surveys/patchSurveyError"
)();

export const getCustomerSurveyRequest = (customerId: string) => {
  return requestActionCreator("@surveys/getCustomerSurvey")({
    request: {
      url: `/surveys/custom-survey/public/${customerId}`,
      method: "GET",
      authenticated: false,
    },
  });
};
export const getCustomerSurveyPending = createStandardAction(
  "@surveys/getCustomerSurveyPending"
)();
export const getCustomerSurveySuccess = createStandardAction(
  "@surveys/getCustomerSurveySuccess"
)<ICustomSurvey, { originalRequest: APIRequestConfig }>();
export const getCustomerSurveyError = createStandardAction(
  "@surveys/getCustomerSurveyError"
)();

export const createCustomSurveyResponseRequest = (payload: {
  customer: string;
  raw: { [key: string]: FormGeneratorOnSubmitParams };
  survey: number;
  useConditionalQuestions: boolean;
}) => {
  return requestActionCreator("@surveys/createCustomSurveyResponse")({
    request: {
      url: "/surveys/custom-responses",
      method: "POST",
      payload,
    },
  });
};
export const createCustomSurveyResponsePending = createStandardAction(
  "@surveys/createCustomSurveyResponsePending"
)();
export const createCustomSurveyResponseSuccess = createStandardAction(
  "@surveys/createCustomSurveyResponseSuccess"
)<ICustomSurveyResponse, { originalRequest: APIRequestConfig }>();
export const createCustomSurveyResponseError = createStandardAction(
  "@surveys/createCustomSurveyResponseError"
)();

export const updateCustomSurveyResponseRequest = (payload: {
  customer: string;
  responseId: number;
  extra_free_text_feedback: string;
  requested_contact: boolean;
}) => {
  return requestActionCreator("@surveys/updateCustomSurveyResponse")({
    request: {
      url: `/surveys/custom-responses/${payload.responseId}`,
      method: "PATCH",
      payload,
    },
  });
};
export const updateCustomSurveyResponsePending = createStandardAction(
  "@surveys/updateCustomSurveyResponsePending"
)();
export const updateCustomSurveyResponseSuccess = createStandardAction(
  "@surveys/updateCustomSurveyResponseSuccess"
)<ICustomSurveyResponse, { originalRequest: APIRequestConfig }>();
export const updateCustomSurveyResponseError = createStandardAction(
  "@surveys/updateCustomSurveyResponseError"
)();

export const getCustomSurveyStatisticsRequest = ({
  surveyId,
  startDate,
  endDate,
}: {
  surveyId: number;
  startDate?: string;
  endDate?: string;
}) => {
  const params: { [key: string]: string } = {};
  if (startDate) {
    params.start_date = startDate;
  }
  if (endDate) {
    params.end_date = endDate;
  }
  return requestActionCreator("@surveys/getCustomSurveyStatistics")({
    request: {
      url: `/surveys/custom-survey/${surveyId}/statistics`,
      method: "GET",
      params,
    },
  });
};
export const getCustomSurveyStatisticsPending = createStandardAction(
  "@surveys/getCustomSurveyStatisticsPending"
)();
export const getCustomSurveyStatisticsSuccess = createStandardAction(
  "@surveys/getCustomSurveyStatisticsSuccess"
)<SurveyMetrics, { originalRequest: APIRequestConfig }>();
export const getCustomSurveyStatisticsError = createStandardAction(
  "@surveys/getCustomSurveyStatisticsError"
)();

export const getLpSurveyStatisticsRequest = () =>
  requestActionCreator("@surveys/getLpSurveyStatistics")({
    request: {
      url: "/responses/lp_statistics",
      method: "GET",
    },
  });
export const getLpSurveyStatisticsPending = createStandardAction(
  "@surveys/getLpSurveyStatisticsPending"
)();
export const getLpSurveyStatisticsSuccess = createStandardAction(
  "@surveys/getLpSurveyStatisticsSuccess"
)<SurveyMetrics, { originalRequest: APIRequestConfig }>();
export const getLpSurveyStatisticsError = createStandardAction(
  "@surveys/getLpSurveyStatisticsError"
)();

export const getGlobalCustomSurveyStatisticsRequest = ({
  surveyId,
  startDate,
  endDate,
}: {
  surveyId: number;
  startDate?: string;
  endDate?: string;
}) => {
  const params: { [key: string]: string } = {};
  if (startDate) {
    params.start_date = startDate;
  }
  if (endDate) {
    params.end_date = endDate;
  }
  return requestActionCreator("@surveys/getGlobalCustomSurveyStatistics")({
    request: {
      url: `/surveys/global-custom-survey/${surveyId}/statistics`,
      method: "GET",
      params,
    },
  });
};
export const getGlobalCustomSurveyStatisticsPending = createStandardAction(
  "@surveys/getGlobalCustomSurveyStatisticsPending"
)();
export const getGlobalCustomSurveyStatisticsSuccess = createStandardAction(
  "@surveys/getGlobalCustomSurveyStatisticsSuccess"
)<SurveyMetrics, { originalRequest: APIRequestConfig }>();
export const getGlobalCustomSurveyStatisticsError = createStandardAction(
  "@surveys/getGlobalCustomSurveyStatisticsError"
)();

export const getHasLpDataRequest = () =>
  requestActionCreator("@surveys/getHasLpData")({
    request: {
      url: "/responses/has_lp_data",
      method: "GET",
    },
  });
export const getHasLpDataPending = createStandardAction(
  "@surveys/getHasLpDataPending"
)();
export const getHasLpDataSuccess = createStandardAction(
  "@surveys/getHasLpDataSuccess"
)<{ has_data: boolean }, { originalRequest: APIRequestConfig }>();
export const getHasLpDataError = createStandardAction(
  "@surveys/getHasLpDataError"
)();

export const setUsingGlobalSurveyMetrics = createStandardAction(
  "@surveys/setUsingGlobalSurveyMetrics"
).map((payload: boolean) => ({ payload }));

export const createGlobalCustomSurveyResponseRequest = (payload: {
  customer: string;
  raw: { [key: string]: FormGeneratorOnSubmitParams };
  survey: number;
}) => {
  return requestActionCreator("@surveys/createGlobalCustomSurveyResponse")({
    request: {
      url: "/surveys/global-custom-responses",
      method: "POST",
      payload,
    },
  });
};
export const createGlobalCustomSurveyResponsePending = createStandardAction(
  "@surveys/createGlobalCustomSurveyResponsePending"
)();
export const createGlobalCustomSurveyResponseSuccess = createStandardAction(
  "@surveys/createGlobalCustomSurveyResponseSuccess"
)<ICustomSurveyResponse, { originalRequest: APIRequestConfig }>();
export const createGlobalCustomSurveyResponseError = createStandardAction(
  "@surveys/createGlobalCustomSurveyResponseError"
)();

export const updateGlobalCustomSurveyResponseRequest = (payload: {
  customer: string;
  responseId: number;
  extra_free_text_feedback: string;
  requested_contact: boolean;
}) => {
  return requestActionCreator("@surveys/updateGlobalCustomSurveyResponse")({
    request: {
      url: `/surveys/global-custom-responses/${payload.responseId}`,
      method: "PATCH",
      payload,
    },
  });
};
export const updateGlobalCustomSurveyResponsePending = createStandardAction(
  "@surveys/updateGlobalCustomSurveyResponsePending"
)();
export const updateGlobalCustomSurveyResponseSuccess = createStandardAction(
  "@surveys/updateGlobalCustomSurveyResponseSuccess"
)<ICustomSurveyResponse, { originalRequest: APIRequestConfig }>();
export const updateGlobalCustomSurveyResponseError = createStandardAction(
  "@surveys/updateGlobalCustomSurveyResponseError"
)();

export const closeSurveyCreatedModal = createStandardAction(
  "@surveys/closeSurveyCreatedModal"
)();
export const openSurveyCreatedModal = createStandardAction(
  "@surveys/openSurveyCreatedModal"
)();

export const closeSurveyOptionsModal = createStandardAction(
  "@surveys/closeSurveyOptionsModal"
)();
export const openSurveyOptionsModal = createStandardAction(
  "@surveys/openSurveyOptionsModal"
).map((payload: number) => ({ payload }));

export const setSurveyPage = createStandardAction(
  "@surveys/setSurveyStep"
).map((payload: SurveyPageName) => ({ payload }));

export const setNewSurveyInitialData = createStandardAction(
  "@surveys/setNewSurveyInitialData"
).map((payload: any[] | null) => ({ payload }));

export const setSelectedSurveyForMetrics = createStandardAction(
  "@surveys/setSelectedSurveyForMetrics"
).map((payload: number | null) => ({ payload }));
